<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">{{ this.details.percent }} Commission</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card no-body id="data">

          <table class="table" v-if="details">
            <tbody>
                <tr v-for="(v, k, index) in details" :key="index"  v-if="k !== 'id' && k !== 'product_details' && k !== 'insurer' && k !== 'product' && k !== 'options' && k !== 'addon_type' && k !== 'rate_or_premium'">
                  <th>{{ k|capitalize }}</th>
                  <td colspan="2" v-if="k === 'addon_type_details' || k === 'rate_or_premium_details'">
                    {{v.name}}
                  </td>
                  <td colspan="2" v-else v-html="v">{{ v }}</td>
                </tr>
            </tbody>

          </table>

        </b-card>

        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>
          <b-button v-show="hasFullAccess" :to="{ name: 'CommissionEdit', params: { product: this.$route.params.product, id: this.$route.params.id } }" variant="outline-dark" class="float-right">Edit</b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";

  export default {
    name: 'Commission',
    components: {Id},
    props: {
      caption: {
        type: String,
        default: 'Commission Details'
      },
    },
    data: () => {
      return {
        details: {
         value: "",
         percent: "",
         csid: "",
        }
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      getData() {

        let url = '/commission/' + this.$route.params.id +  '/?product=' + this.$route.params.product;

        axios.get(url).then(
          response => {
            this.details = response.data
          }
        ).catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
            console.log('email setting error');
            console.log(error.response)
          })
        return this.details
      }
    },
    computed: {
      hasFullAccess() {

        let access = ['full_admin',];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      },
    },
    mounted() {
      this.getData()
    },
    watch: {
      $route(to, from) {
        // react to route changes...
        this.$router.replace(to.fullPath).catch(error => {
          console.log(error)
        }).then(
          this.getData()
        );

      }
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
